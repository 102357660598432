<template>
  <div>
    <div>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formatedDate"
            label="Data de nascimento *"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          locale="pt-BR"
          v-model="date"
          :active-picker.sync="activePicker"
          :max="new Date().toISOString().substr(0, 10)"
          min="1950-01-01"
          @change="save"
        ></v-date-picker>
      </v-menu>
    </div>
    <v-text-field disabled label="Idade" :value="age"></v-text-field>
  </div>
</template>

<script>
import { parseISO, format, formatDistanceToNowStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default {
  data: () => ({
    activePicker: null,
    date: null,
    menu: false,
  }),
  props: {
    dateOfBirth: {
      type: [Date, String],
      required: false,
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
    dateOfBirth(newDate) {
      this.date = newDate;
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
      if (date) {
        this.$emit('update:dateOfBirth', new Date(date));
        this.$emit('dateChanged');
      }
    },
  },
  computed: {
    age() {
      return this.date
        ? formatDistanceToNowStrict(parseISO(this.date), { locale: ptBR, roundingMethod: 'floor' })
        : '';
    },
    formatedDate() {
      return this.date ? format(parseISO(this.date), 'dd/MM/yyyy') : '';
    },
  },
};
</script>
