<template>
  <v-slide-x-reverse-transition>
    <div>
      <v-card class="mx-auto" max-width="700" elevation="3">
        <v-card-title class="pl-10 pt-5"> Dados da Criança </v-card-title>
        <v-card-text class="pa-10">
          <v-form>
            <v-text-field
              autofocus
              label="Nome"
              v-model="assessment.patient.name"
            ></v-text-field>
            <v-select
              :items="schoolYear"
              label="Ano Escolar *"
              v-model="assessment.patient.schoolYear"
              @change="onInputChange"
            ></v-select>
            <v-text-field
              label="Escola *"
              v-model="assessment.patient.schoolName"
              @change="onInputChange"
            ></v-text-field>
            <v-select
              :items="schoolType"
              label="Tipo Escola *"
              v-model="assessment.patient.schoolType"
              @change="onInputChange"
            ></v-select>
            <BirthdayPicker
              :dateOfBirth.sync="assessment.patient.dateOfBirth"
              @dateChanged="onInputChange"
            />
            <v-select
              :items="gender"
              label="Sexo *"
              v-model="assessment.patient.gender"
              @change="onInputChange"
            ></v-select>
            <v-select
              :items="state"
              label="Estado *"
              v-model="assessment.patient.state"
              @change="onInputChange"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!enabled" @click="onContinue" color="#DCE317">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-slide-x-reverse-transition>
</template>

<script>
import BirthdayPicker from "./BirthdayPicker";
import { api } from "../services/api.js";

export default {
  components: {
    BirthdayPicker,
  },

  provide: function() {
    return {
      assessment: this.assessment,
    };
  },

  data: () => ({
    enabled: false,
    schoolYear: [
      "Ensino Infantil",
      "1º EF",
      "2º EF",
      "3º EF",
      "4º EF",
      "5º EF",
      "6º EF",
      "7º EF",
      "8º EF",
    ],
    schoolType: ["Pública", "Particular"],
    gender: ["Masculino", "Feminino"],
    state: [
      "Acre",
      "Alagoas",
      "Amapá",
      "Amazonas",
      "Bahia",
      "Ceará",
      "Distrito Federal",
      "Espírito Santo",
      "Goiás",
      "Maranhão",
      "Mato Grosso do Sul",
      "Mato Grosso",
      "Minas Gerais",
      "Pará",
      "Paraíba",
      "Paraná",
      "Pernambuco",
      "Piauí",
      "Rio de Janeiro",
      "Rio Grande do Norte",
      "Rio Grande do Sul",
      "Rondônia",
      "Roraima",
      "Santa Catarina",
      "São Paulo",
      "Sergipe",
      "Tocantins",
    ],
    assessment: {
      patient: {
        name: "",
        schoolYear: "",
        schoolName: "",
        schoolType: "",
        dateOfBirth: "",
        gender: "",
        state: "",
      },
    },
  }),

  created: async function() {
    this.assessment = await api.assessments.get(this.$route.params.id);
    this.onInputChange();
  },
  methods: {
    onInputChange() {
      if (
        this.assessment.patient.schoolYear &&
        this.assessment.patient.schoolName &&
        this.assessment.patient.schoolType &&
        this.assessment.patient.dateOfBirth &&
        this.assessment.patient.gender &&
        this.assessment.patient.state
      ) {
        this.enabled = true;
      } else {
        this.enabled = false;
      }
    },
    onContinue: async function() {
      this.assessment.status = "Em Andamento";
      await api.assessments.patch(this.$route.params.id, this.assessment);
      this.$router.push({ path: `/teste/${this.$route.params.id}/instrucoes` });
    },
  },
};
</script>
