var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"700","elevation":"3"}},[_c('v-card-title',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCardTitle),expression:"showCardTitle"}],staticClass:"pl-10 pt-5"},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('h4',{staticClass:"text-center mt-2 ml-2"},[_vm._v(" "+_vm._s(_vm.historyTitle)+" ")]),_c('v-btn',{attrs:{"disabled":_vm.soundPlaying,"icon":"","color":"primary"},on:{"click":function($event){return _vm.onListen(_vm.historyTitle + ' - hist')}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-volume-high")])]}}])},[_c('span',[_vm._v("Ouvir")])])],1)],1),_c('v-card-text',{staticClass:"pa-10"},[_c('p',{staticClass:"text-center font-weight-medium"},[_vm._v(" "+_vm._s(_vm.historyDescription)+" ")]),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"disabled":_vm.soundPlaying,"icon":"","color":"primary"},on:{"click":function($event){return _vm.onListen(_vm.historyTitle + ' - ad')}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-volume-high")])]}}])},[_c('span',[_vm._v("Ouvir")])])],1)],1),_c('v-img',{attrs:{"src":require(("@/assets/" + _vm.historyPicture))}}),_c('p',{staticClass:"text-center font-weight-medium mt-6"},[_vm._v(" "+_vm._s(_vm.historyQuestion)+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"small-radio-label",on:{"change":function($event){return _vm.$emit('choiceSelected', _vm.option)}},model:{value:(_vm.option),callback:function ($$v) {_vm.option=$$v},expression:"option"}},[_c('v-radio',{attrs:{"label":_vm.optionA,"value":"A"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"inline-content"},[_vm._v(" "+_vm._s(_vm.optionA)+" "),_c('v-btn',{attrs:{"disabled":_vm.soundPlaying,"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.onListen(_vm.historyTitle + ' - optA')}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-volume-high")])]}}])},[_c('span',[_vm._v("Ouvir")])])],1)],1)]},proxy:true}])}),_c('v-radio',{attrs:{"label":_vm.optionB,"value":"B"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"inline-content"},[_vm._v(" "+_vm._s(_vm.optionB)+" "),_c('v-btn',{attrs:{"disabled":_vm.soundPlaying,"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.onListen(_vm.historyTitle + ' - optB')}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-volume-high")])]}}])},[_c('span',[_vm._v("Ouvir")])])],1)],1)]},proxy:true}])}),_c('v-radio',{attrs:{"label":_vm.optionC,"value":"C"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"inline-content"},[_c('span',{staticClass:"radio-label"},[_vm._v(_vm._s(_vm.optionC))]),_c('v-btn',{attrs:{"disabled":_vm.soundPlaying,"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.onListen(_vm.historyTitle + ' - optC')}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-volume-high")])]}}])},[_c('span',[_vm._v("Ouvir")])])],1)],1)]},proxy:true}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBackBtn),expression:"showBackBtn"}],attrs:{"text":""},on:{"click":function($event){return _vm.$emit('backFromHistory')}}},[_vm._v(" "+_vm._s(_vm.backBtnText)+" ")]),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContinueBtn),expression:"showContinueBtn"}],attrs:{"color":"#DCE317"},on:{"click":function($event){return _vm.$emit('continueHistory')}}},[_vm._v(" "+_vm._s(_vm.continueBtnText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }