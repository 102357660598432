<template>
  <div>
    <v-card class="mx-auto" max-width="700" elevation="3">
      <v-card-title v-show="showCardTitle" class="pl-10 pt-5">
        {{ cardTitle }}
      </v-card-title>
      <v-row class="d-flex justify-center">
        <h4 class="text-center mt-2 ml-2">
          {{ historyTitle }}
        </h4>
        <v-btn :disabled="soundPlaying" icon color="primary" @click="onListen(historyTitle + ' - hist')">
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-volume-high</v-icon>
            </template>
            <span>Ouvir</span>
          </v-tooltip>
        </v-btn>
      </v-row>
      <v-card-text class="pa-10">
        <p class="text-center font-weight-medium">
          {{ historyDescription }}
        </p>
        <div class="d-flex justify-end">
          <v-btn :disabled="soundPlaying" icon color="primary" @click="onListen(historyTitle + ' - ad')">
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-volume-high</v-icon>
              </template>
              <span>Ouvir</span>
            </v-tooltip>
          </v-btn>
        </div>
        <v-img :src="require(`@/assets/${historyPicture}`)"></v-img>
        <p class="text-center font-weight-medium mt-6">
          {{ historyQuestion }}
        </p>
        <v-row no-gutters>
          <v-col cols="12">
            <v-radio-group class="small-radio-label" v-model="option" @change="$emit('choiceSelected', option)">
              <v-radio :label="optionA" value="A">
                <template v-slot:label>
                  <span class="inline-content">
                    {{ optionA }}
                    <v-btn
                      :disabled="soundPlaying"
                      icon
                      color="primary"
                      @click.stop="onListen(historyTitle + ' - optA')"
                    >
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">mdi-volume-high</v-icon>
                        </template>
                        <span>Ouvir</span>
                      </v-tooltip>
                    </v-btn>
                  </span>
                </template>
              </v-radio>
              <v-radio :label="optionB" value="B">
                <template v-slot:label>
                  <span class="inline-content">
                    {{ optionB }}
                    <v-btn
                      :disabled="soundPlaying"
                      icon
                      color="primary"
                      @click.stop="onListen(historyTitle + ' - optB')"
                    >
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">mdi-volume-high</v-icon>
                        </template>
                        <span>Ouvir</span>
                      </v-tooltip>
                    </v-btn>
                  </span>
                </template>
              </v-radio>
              <v-radio :label="optionC" value="C">
                <template v-slot:label>
                  <div class="inline-content">
                    <span class="radio-label">{{ optionC }}</span>
                    <v-btn
                      :disabled="soundPlaying"
                      icon
                      color="primary"
                      @click.stop="onListen(historyTitle + ' - optC')"
                    >
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">mdi-volume-high</v-icon>
                        </template>
                        <span>Ouvir</span>
                      </v-tooltip>
                    </v-btn>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn v-show="showBackBtn" @click="$emit('backFromHistory')" text>
          {{ backBtnText }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-show="showContinueBtn" @click="$emit('continueHistory')" color="#DCE317">
          {{ continueBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { Howl } from 'howler';

export default {
  data() {
    return {
      option: this.choice,
      sound: null,
      progress: 0,
      showPlayer: false,
      soundPlaying: false,
    };
  },

  props: {
    showCardTitle: Boolean,
    cardTitle: String,
    historyTitle: String,
    historyDescription: String,
    historyPicture: String,
    historyQuestion: String,
    optionA: String,
    optionB: String,
    optionC: String,
    showBackBtn: Boolean,
    backBtnText: String,
    showContinueBtn: Boolean,
    continueBtnText: String,
    choice: String,
  },

  methods: {
    onListen: function (id) {
      const soundFile = {
        'HISTÓRIA - hist': () => require('../assets/audios/MRI-exemplo.mp3'),
        'HISTÓRIA - ad': () => require('../assets/audios/MRI-exemplo-ad.mp3'),
        'HISTÓRIA - optA': () => require('../assets/audios/MRI-ExemploA.mp3'),
        'HISTÓRIA - optB': () => require('../assets/audios/MRI-ExemploB.mp3'),
        'HISTÓRIA - optC': () => require('../assets/audios/MRI-ExemploC.mp3'),
        'HISTÓRIA 1 DE 22 - hist': () => require('../assets/audios/MRI1.mp3'),
        'HISTÓRIA 1 DE 22 - ad': () => require('../assets/audios/MRI1-ad.mp3'),
        'HISTÓRIA 1 DE 22 - optA': () => require('../assets/audios/MRI1A.mp3'),
        'HISTÓRIA 1 DE 22 - optB': () => require('../assets/audios/MRI1B.mp3'),
        'HISTÓRIA 1 DE 22 - optC': () => require('../assets/audios/MRI1C.mp3'),
        'HISTÓRIA 2 DE 22 - hist': () => require('../assets/audios/MRI2.mp3'),
        'HISTÓRIA 2 DE 22 - ad': () => require('../assets/audios/MRI2-ad.mp3'),
        'HISTÓRIA 2 DE 22 - optA': () => require('../assets/audios/MRI2A.mp3'),
        'HISTÓRIA 2 DE 22 - optB': () => require('../assets/audios/MRI2B.mp3'),
        'HISTÓRIA 2 DE 22 - optC': () => require('../assets/audios/MRI2C.mp3'),
        'HISTÓRIA 3 DE 22 - hist': () => require('../assets/audios/MRI3.mp3'),
        'HISTÓRIA 3 DE 22 - ad': () => require('../assets/audios/MRI3-ad.mp3'),
        'HISTÓRIA 3 DE 22 - optA': () => require('../assets/audios/MRI3A.mp3'),
        'HISTÓRIA 3 DE 22 - optB': () => require('../assets/audios/MRI3B.mp3'),
        'HISTÓRIA 3 DE 22 - optC': () => require('../assets/audios/MRI3C.mp3'),
        'HISTÓRIA 4 DE 22 - hist': () => require('../assets/audios/MRI4.mp3'),
        'HISTÓRIA 4 DE 22 - ad': () => require('../assets/audios/MRI4-ad.mp3'),
        'HISTÓRIA 4 DE 22 - optA': () => require('../assets/audios/MRI4A.mp3'),
        'HISTÓRIA 4 DE 22 - optB': () => require('../assets/audios/MRI4B.mp3'),
        'HISTÓRIA 4 DE 22 - optC': () => require('../assets/audios/MRI4C.mp3'),
        'HISTÓRIA 5 DE 22 - hist': () => require('../assets/audios/MRI5.mp3'),
        'HISTÓRIA 5 DE 22 - ad': () => require('../assets/audios/MRI5-ad.mp3'),
        'HISTÓRIA 5 DE 22 - optA': () => require('../assets/audios/MRI5A.mp3'),
        'HISTÓRIA 5 DE 22 - optB': () => require('../assets/audios/MRI5B.mp3'),
        'HISTÓRIA 5 DE 22 - optC': () => require('../assets/audios/MRI5C.mp3'),
        'HISTÓRIA 6 DE 22 - hist': () => require('../assets/audios/MRI6.mp3'),
        'HISTÓRIA 6 DE 22 - ad': () => require('../assets/audios/MRI6-ad.mp3'),
        'HISTÓRIA 6 DE 22 - optA': () => require('../assets/audios/MRI6A.mp3'),
        'HISTÓRIA 6 DE 22 - optB': () => require('../assets/audios/MRI6B.mp3'),
        'HISTÓRIA 6 DE 22 - optC': () => require('../assets/audios/MRI6C.mp3'),
        'HISTÓRIA 7 DE 22 - hist': () => require('../assets/audios/MRI7.mp3'),
        'HISTÓRIA 7 DE 22 - ad': () => require('../assets/audios/MRI7-ad.mp3'),
        'HISTÓRIA 7 DE 22 - optA': () => require('../assets/audios/MRI7A.mp3'),
        'HISTÓRIA 7 DE 22 - optB': () => require('../assets/audios/MRI7B.mp3'),
        'HISTÓRIA 7 DE 22 - optC': () => require('../assets/audios/MRI7C.mp3'),
        'HISTÓRIA 8 DE 22 - hist': () => require('../assets/audios/MRI8.mp3'),
        'HISTÓRIA 8 DE 22 - ad': () => require('../assets/audios/MRI8-ad.mp3'),
        'HISTÓRIA 8 DE 22 - optA': () => require('../assets/audios/MRI8A.mp3'),
        'HISTÓRIA 8 DE 22 - optB': () => require('../assets/audios/MRI8B.mp3'),
        'HISTÓRIA 8 DE 22 - optC': () => require('../assets/audios/MRI8C.mp3'),
        'HISTÓRIA 9 DE 22 - hist': () => require('../assets/audios/MRI9.mp3'),
        'HISTÓRIA 9 DE 22 - ad': () => require('../assets/audios/MRI9-ad.mp3'),
        'HISTÓRIA 9 DE 22 - optA': () => require('../assets/audios/MRI9A.mp3'),
        'HISTÓRIA 9 DE 22 - optB': () => require('../assets/audios/MRI9B.mp3'),
        'HISTÓRIA 9 DE 22 - optC': () => require('../assets/audios/MRI9C.mp3'),
        'HISTÓRIA 10 DE 22 - hist': () => require('../assets/audios/MRI10.mp3'),
        'HISTÓRIA 10 DE 22 - ad': () => require('../assets/audios/MRI10-ad.mp3'),
        'HISTÓRIA 10 DE 22 - optA': () => require('../assets/audios/MRI10A.mp3'),
        'HISTÓRIA 10 DE 22 - optB': () => require('../assets/audios/MRI10B.mp3'),
        'HISTÓRIA 10 DE 22 - optC': () => require('../assets/audios/MRI10C.mp3'),
        'HISTÓRIA 11 DE 22 - hist': () => require('../assets/audios/MRI11.mp3'),
        'HISTÓRIA 11 DE 22 - ad': () => require('../assets/audios/MRI11-ad.mp3'),
        'HISTÓRIA 11 DE 22 - optA': () => require('../assets/audios/MRI11A.mp3'),
        'HISTÓRIA 11 DE 22 - optB': () => require('../assets/audios/MRI11B.mp3'),
        'HISTÓRIA 11 DE 22 - optC': () => require('../assets/audios/MRI11C.mp3'),
        'HISTÓRIA 12 DE 22 - hist': () => require('../assets/audios/MRI12.mp3'),
        'HISTÓRIA 12 DE 22 - ad': () => require('../assets/audios/MRI12-ad.mp3'),
        'HISTÓRIA 12 DE 22 - optA': () => require('../assets/audios/MRI12A.mp3'),
        'HISTÓRIA 12 DE 22 - optB': () => require('../assets/audios/MRI12B.mp3'),
        'HISTÓRIA 12 DE 22 - optC': () => require('../assets/audios/MRI12C.mp3'),
        'HISTÓRIA 13 DE 22 - hist': () => require('../assets/audios/MRI13.mp3'),
        'HISTÓRIA 13 DE 22 - ad': () => require('../assets/audios/MRI13-ad.mp3'),
        'HISTÓRIA 13 DE 22 - optA': () => require('../assets/audios/MRI13A.mp3'),
        'HISTÓRIA 13 DE 22 - optB': () => require('../assets/audios/MRI13B.mp3'),
        'HISTÓRIA 13 DE 22 - optC': () => require('../assets/audios/MRI13C.mp3'),
        'HISTÓRIA 14 DE 22 - hist': () => require('../assets/audios/MRI14.mp3'),
        'HISTÓRIA 14 DE 22 - ad': () => require('../assets/audios/MRI14-ad.mp3'),
        'HISTÓRIA 14 DE 22 - optA': () => require('../assets/audios/MRI14A.mp3'),
        'HISTÓRIA 14 DE 22 - optB': () => require('../assets/audios/MRI14B.mp3'),
        'HISTÓRIA 14 DE 22 - optC': () => require('../assets/audios/MRI14C.mp3'),
        'HISTÓRIA 15 DE 22 - hist': () => require('../assets/audios/MRI15.mp3'),
        'HISTÓRIA 15 DE 22 - ad': () => require('../assets/audios/MRI15-ad.mp3'),
        'HISTÓRIA 15 DE 22 - optA': () => require('../assets/audios/MRI15A.mp3'),
        'HISTÓRIA 15 DE 22 - optB': () => require('../assets/audios/MRI15B.mp3'),
        'HISTÓRIA 15 DE 22 - optC': () => require('../assets/audios/MRI15C.mp3'),
        'HISTÓRIA 16 DE 22 - hist': () => require('../assets/audios/MRI16.mp3'),
        'HISTÓRIA 16 DE 22 - ad': () => require('../assets/audios/MRI16-ad.mp3'),
        'HISTÓRIA 16 DE 22 - optA': () => require('../assets/audios/MRI16A.mp3'),
        'HISTÓRIA 16 DE 22 - optB': () => require('../assets/audios/MRI16B.mp3'),
        'HISTÓRIA 16 DE 22 - optC': () => require('../assets/audios/MRI16C.mp3'),
        'HISTÓRIA 17 DE 22 - hist': () => require('../assets/audios/MRI17.mp3'),
        'HISTÓRIA 17 DE 22 - ad': () => require('../assets/audios/MRI17-ad.mp3'),
        'HISTÓRIA 17 DE 22 - optA': () => require('../assets/audios/MRI17A.mp3'),
        'HISTÓRIA 17 DE 22 - optB': () => require('../assets/audios/MRI17B.mp3'),
        'HISTÓRIA 17 DE 22 - optC': () => require('../assets/audios/MRI17C.mp3'),
        'HISTÓRIA 18 DE 22 - hist': () => require('../assets/audios/MRI18.mp3'),
        'HISTÓRIA 18 DE 22 - ad': () => require('../assets/audios/MRI18-ad.mp3'),
        'HISTÓRIA 18 DE 22 - optA': () => require('../assets/audios/MRI18A.mp3'),
        'HISTÓRIA 18 DE 22 - optB': () => require('../assets/audios/MRI18B.mp3'),
        'HISTÓRIA 18 DE 22 - optC': () => require('../assets/audios/MRI18C.mp3'),
        'HISTÓRIA 19 DE 22 - hist': () => require('../assets/audios/MRI19.mp3'),
        'HISTÓRIA 19 DE 22 - ad': () => require('../assets/audios/MRI19-ad.mp3'),
        'HISTÓRIA 19 DE 22 - optA': () => require('../assets/audios/MRI19A.mp3'),
        'HISTÓRIA 19 DE 22 - optB': () => require('../assets/audios/MRI19B.mp3'),
        // 'HISTÓRIA 19 DE 22 - optC': () => require('../assets/audios/MRI19C.mp3'),
        'HISTÓRIA 20 DE 22 - hist': () => require('../assets/audios/MRI20.mp3'),
        'HISTÓRIA 20 DE 22 - ad': () => require('../assets/audios/MRI20-ad.mp3'),
        'HISTÓRIA 20 DE 22 - optA': () => require('../assets/audios/MRI20A.mp3'),
        'HISTÓRIA 20 DE 22 - optB': () => require('../assets/audios/MRI20B.mp3'),
        'HISTÓRIA 20 DE 22 - optC': () => require('../assets/audios/MRI20C.mp3'),
        'HISTÓRIA 21 DE 22 - hist': () => require('../assets/audios/MRI21.mp3'),
        'HISTÓRIA 21 DE 22 - ad': () => require('../assets/audios/MRI21-ad.mp3'),
        'HISTÓRIA 21 DE 22 - optA': () => require('../assets/audios/MRI21A.mp3'),
        'HISTÓRIA 21 DE 22 - optB': () => require('../assets/audios/MRI21B.mp3'),
        'HISTÓRIA 21 DE 22 - optC': () => require('../assets/audios/MRI21C.mp3'),
        'HISTÓRIA 22 DE 22 - hist': () => require('../assets/audios/MRI22.mp3'),
        'HISTÓRIA 22 DE 22 - ad': () => require('../assets/audios/MRI22-ad.mp3'),
        'HISTÓRIA 22 DE 22 - optA': () => require('../assets/audios/MRI22A.mp3'),
        'HISTÓRIA 22 DE 22 - optB': () => require('../assets/audios/MRI22B.mp3'),
        'HISTÓRIA 22 DE 22 - optC': () => require('../assets/audios/MRI22C.mp3'),
      }
      const sound = new Howl({
        src: [soundFile[id]()],
        onend: function () {
          this.soundPlaying = false;
        }.bind(this)
      });
      this.soundPlaying = true;
      sound.play();
    },
  },
};
</script>

<style scoped>
.small-radio-label>>>label {
  font-size: 14px;
  font-weight: 500;
}

.inline-content {
  display: inline;
  /* Treats the label and button as part of the same inline flow */
}

.radio-label {
  white-space: normal;
  /* Allows the label to break into multiple lines */
  word-break: break-word;
  /* Ensures long words break properly */
}

.inline-button {
  margin-left: 8px;
  /* Adds spacing between the label and the button */
  vertical-align: middle;
  /* Aligns the button with the text */
}

.d-flex {
  display: flex;
}

.justify-end {
  justify-content: flex-end;
}

.sound-player {
  position: relative;
  display: inline-block;
}

.player-controls {
  display: flex;
  align-items: center;
}

.progress-bar {
  flex-grow: 1;
  margin-left: 8px;
}
</style>
